#app {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*Style for Cognito Login*/
.Section__container___3YYTG {
  font-weight: 400;
}

.Form__formContainer___1GA3x {
  text-align: center;

  margin: 5% auto 50px;
}

.Form__formSection___1PPvW {
  position: relative;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 15%);
  box-sizing: border-box;
}

.Section__sectionHeader___2djyg {
  color: #152939;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
}

.Section__sectionBody___ihqqd {
  margin-bottom: 30px;
}

.Section__sectionFooter___1T54C {
  font-size: 14px;
  color: #828282;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.Form__formField___38Ikl {
  margin-bottom: 22px;
}

.Input__inputLabel___3VF0S,
.Input__label___23sO8 {
  color: #152939;
}

.Input__input___3e_bf {
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  color: #152939;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.Input__inputLabel___3VF0S {
  font-size: 14px;
  margin-bottom: 8px;
}

a.Anchor__a___1_Iz8 {
  color: #1377fa;
  cursor: pointer;
}
.Hint__hint___2XngB {
  color: #828282;
  font-size: 12px;
}
.Section__sectionFooter___1T54C {
  font-size: 14px;
  color: #828282;
  color: grey;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.Section__sectionFooterPrimaryContent___2r9ZX {
  margin-left: auto;
}
.Section__sectionFooterSecondaryContent___Nj41Q {
  margin-right: auto;
  align-self: center;
}
.Button__button___vS7Mv {
  min-width: 153px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  color: #fff;
  background-color: #1377fa;
  text-transform: uppercase;
  padding: 14px 0;
  letter-spacing: 1.1px;
  border: none;
}

html,
body {
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  max-height: 100vh;
  max-width: 100vw;
}
